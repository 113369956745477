import React from "react";
import { Box, CircularProgress } from "@mui/material";
const CustomSpinner = () => {
  return (
    <Box sx={{ display: "flex", justifyContent:"center" }}>
      <CircularProgress />
    </Box>
  );
};

export default CustomSpinner;
