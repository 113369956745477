import { Typography } from "@mui/material";
import React from "react";
import AttributeSelector from "./Forms/AttributeSelector";

export default function Step4() {
 
  return (
    <>
      <Typography>
        Indiquez ici tout détail supplémentaire vous permettant d'authentifier le bon propriétaire (Ils ne seront visibles que par vous !)
      </Typography>

      <AttributeSelector isSecret={true}/>
    </>
  );
}
