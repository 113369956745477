import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { AppConversionRates, AppWebsiteVisits, AppWidgetSummary } from 'src/sections/@dashboard/app';
import { format } from 'date-fns';
import { getCategories } from 'src/service/categoriesApi';
import { getCustomersAds } from 'src/service/customerApi';
import AuthContext from 'src/contexts/AuthContext';

export default function Statistics() {
    const [foundAds, setFoundAds] = useState([]);
    const [sentAds, setSentAds] = useState([]);
    const [sendingAds, setSendingAds] = useState([]);
    const [lostAds, setLostAds] = useState([]);
    const [dates, setDates] = useState([]);
    const [lastSendingAds, setLastSendingAds] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [lastFoundAds, setLastFoundAds] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [lastSentAds, setLastSentAds] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [allAds, setAllAds] = useState([]);
    const [categories, setCategories] = useState([]);
    const [adsByCategory, setAdsByCategory] = useState([]);
    const { customerId } = useContext(AuthContext)
    const [foundAdsByCategory, setFoundAdsByCategory] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [sentAdsByCategory, setSentAdsByCategory] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchdAds = async (id, params, status) => {
        try {
            const data = await getCustomersAds(id, params + status);
            if (params === "?origin=" && status === "TROUVÉ") {
                setFoundAds(data);
            }
            if (status === "RESTITUÉ") {
                setSentAds(data);
                console.log("data", data)
            }
            if (status === "ENVOYÉ") {
                setSendingAds(data);
            }
            if (params === "?status=" && status === "TROUVÉ") {
                setLostAds(data);
            }
            if (status === "") {
                setAllAds(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getLast10Days = () => {
        let datesList = [];
        let today = new Date();
        datesList.push(format(today, 'MM/dd/yyyy'));
        for (let i = 0; i < 365; i++) {
            today.setDate(today.getDate() - 1);
            datesList.push(format(today, 'MM/dd/yyyy'));
        }
        setDates(datesList.reverse());
    }

    const getLastFoundAds = (ads) => {
        const adsList = dates.map((date, index) => {
            const count = ads.filter((ad) => {
                if (format(new Date(ad.createdAt), 'MM/dd/yyyy') === date)
                    return true;
                return false;
            })
            getLastAdsByCategory(count, index, foundAdsByCategory, setFoundAdsByCategory)
            return count.length
        })
        return adsList;
    }

    const getLastAdsSent = (ads) => {
        const adsList = dates.map((date, index) => {
            const count = ads.filter((ad) => {
                if (format(new Date(ad.updatedAt), 'MM/dd/yyyy') === date)
                    return true;
                return false;

            })
            getLastAdsByCategory(count, index, sentAdsByCategory, setSentAdsByCategory)
            return count.length
        })
        return adsList;
    }

    const getLastAdsSending = (ads) => {
        const adsList = dates.map((date, index) => {
            const count = ads.filter((ad) => {
                if (format(new Date(ad.updatedAt), 'MM/dd/yyyy') === date)
                    return true;
                return false;

            })
            return count.length
        })
        return adsList;
    }

    const getLastAdsByCategory = (ads, dayIndex, AdsData, setAdsFunction) => {
        let adsList = categories.map((category) => {
            return 0
        })
        
        ads.forEach((ad) => {
            const matchingCategory = categories.findIndex((category) => category.id === ad.category.id)

            if (matchingCategory > -1) {
                adsList[matchingCategory] += 1
            }
        })/*
        adsList.forEach((item, indexCategorie) => {
            setAdsFunction(AdsData => {
                let newAdsByCategory = [...AdsData];
                newAdsByCategory[indexCategorie][dayIndex] = item;
                return newAdsByCategory;
            })
        })*/
    }

    const getAdsByCategoryAttribute = (count, subCategories) => {
        if (!subCategories || subCategories === "")
            return

        const subAttributes = subCategories.attributes.map((attribute) => {
            return { name: attribute.name, value: 0 }
        })
        count.forEach((item) => {
            const matchingAttribute = item.annonceAttributes.find(
                (attribute) => attribute.attribute === subCategories.name
            );
            if (!matchingAttribute)
                return;
            const index = subAttributes.findIndex((subAttribute) => subAttribute.name === matchingAttribute.attributeValue)
            if (index > -1)
                subAttributes[index].value += 1
        })

        return subAttributes
    }    

    const getSubCategories = (category) => {
        switch (category.id) {
            case 3:
                return getCategoryAttribute(category, "Animal")
            case 4:
                return getCategoryAttribute(category, "Marque Smartphone")
            case 5:
                return getCategoryAttribute(category, "Type de vêtement")
            case 8:
                return getCategoryAttribute(category, "Type d'objet multimedia")
            case 9:
                return getCategoryAttribute(category, "Type de lunettes")
            case 16:
                return getCategoryAttribute(category, "Type de clés")
            case 17:
                return getCategoryAttribute(category, "Type de carte")
            case 18:
                return getCategoryAttribute(category, "type bijoux")
            case 19:
                return getCategoryAttribute(category, "Forme de doudou")
            case 20:
                return getCategoryAttribute(category, "Type de sac / bagage")
            case 22:
                return getCategoryAttribute(category, "Type de papier d'identité")
            default:
                return ""
        }
    }

    const getCategoryAttribute = (category, subCategories) => {
        const matchingAttribute = category.attribute.find(
            (attribute) => attribute.name === subCategories
        );
        return matchingAttribute
    }

    const getAdsByCategory = () => {
        const adsList = categories.map((category) => {
            const count = allAds.filter((ad) => {
                if (ad.category.id === category.id)
                    return true;
                return false;
            })
            const subCategories = getSubCategories(category)
            const subAttributes = getAdsByCategoryAttribute(count, subCategories)
            let body = { label: category.name, value: count.length }
            if (subAttributes) {
                body = subAttributes.reduce((result, attribute, index) => {
                const subCategoryNameKey = `subCategorieName${index + 1}`;
                const subCategoryValueKey = `subCategorieValue${index + 1}`;
                return {
                    ...result,
                    [subCategoryNameKey]: attribute.name,
                    [subCategoryValueKey]: attribute.value
                };
                }, { label: category.name, value: count.length });
            }
            return body
        })
        return adsList;
    }

    const generateCSVCategory = () => {
        const csvRows = adsByCategory.map((item) => Object.values(item).join(','));
        createCSV(csvRows)
    };

    const generateCSVCategoryFounds = (Ads) => {
        const csvRows = Ads.map((item, index) => {
            const categoryName = categories[index].name
            return [categoryName, ...Object.values(item)].join(',')
        });

        csvRows.unshift("Dates," + dates.join(','))
        createCSV(csvRows)
    };

    const generateCSVObject = () => {
        const csvRows = dates.map((date, index) => [date, lastSendingAds[index], lastFoundAds[index], lastSentAds[index]].join(','));
        csvRows.unshift("Date,Objets envoyés,Objets trouvés,Objets restitués")
        createCSV(csvRows)
    };

    const createCSV = (csvRows) => {
        const csvData = csvRows.join('\n');

        const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const csvUrl = URL.createObjectURL(csvBlob);

        window.open(csvUrl, '_blank');
    }

    useEffect(() => {
        getCategories().then((resp) => {
            setCategories(resp);
            const initialTab = resp.map(() => Array(365).fill(0).map((value) => value));
            const initialTab2 = resp.map(() => Array(365).fill(0).map((value) => value));
            setFoundAdsByCategory(initialTab)
            setSentAdsByCategory(initialTab2)
        })
        fetchdAds(customerId, "?origin=", "TROUVÉ")
        fetchdAds(customerId, "?status=", "RESTITUÉ")
        fetchdAds(customerId, "?status=", "ENVOYÉ")
        fetchdAds(customerId, "?status=", "TROUVÉ")
        fetchdAds(customerId, "", "")
        getLast10Days()
    }, []);

    useEffect(() => {
        if (dates.length > 0 && sendingAds.length > 0) {
            setLastSendingAds(getLastAdsSending(sendingAds))
        }
    }, [dates, sendingAds]);

    useEffect(() => {
        if (dates.length > 0 && foundAds.length > 0 && categories.length > 0) {
            setLastFoundAds(getLastFoundAds(foundAds))
        }
    }, [dates, foundAds, categories]);

    useEffect(() => {
        if (dates.length > 0 && sentAds.length > 0 && categories.length > 0) {
            setLastSentAds(getLastAdsSent(sentAds))
        }
    }, [dates, sentAds, categories]);

    useEffect(() => {
        if (allAds.length > 0 && categories.length > 0) {
            setAdsByCategory(getAdsByCategory())
        }
    }, [allAds, categories]);

    useEffect(() => {
        if (foundAdsByCategory.length > 0 && sentAdsByCategory.length > 0 && categories.length > 0) {
            setIsLoaded(true)
        }
    }, [foundAdsByCategory, sentAdsByCategory, categories])

    return (
        <Grid container spacing={3} justifyContent="center" mt={2}>
            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Objet trouvés" total={foundAds.length} icon={'gg:search-found'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Objet Restitué en Main Propre" total={sentAds.length} color="warning" icon={'la:hands-helping'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Objet en cours de livraison" total={sendingAds.length} icon={'akar-icons:shipping-box-v1'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Objet sans propriétaire" total={lostAds.length} color="warning" icon={'ant-design:bug-filled'} />
            </Grid>
            <Grid item xs={12} md={6} lg={8} my={2}>
                <AppWebsiteVisits
                    title="Suivi des objets par jour"
                    chartLabels={dates ? dates : [
                        "06/13/2023",
                        "06/14/2023",
                        "06/15/2023",
                        "06/16/2023",
                        "06/17/2023",
                        "06/18/2023",
                        "06/19/2023",
                        "06/20/2023",
                        "06/21/2023",
                        "06/22/2023",
                    ]}
                    chartData={[
                        {
                            name: 'Objets en cours de livraison',
                            type: 'column',
                            fill: 'solid',
                            data: lastSendingAds,
                        },
                        {
                            name: 'Objets trouvés',
                            type: 'area',
                            fill: 'gradient',
                            data: lastFoundAds,
                        },
                        {
                            name: 'Objets restitués en main propre',
                            type: 'line',
                            fill: 'solid',
                            data: lastSentAds,
                        },
                    ]}
                    generateCSVObject={generateCSVObject}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={8} my={2}>
                <AppWebsiteVisits
                    title="Suivi des objets par catégorie et par jour"
                    chartLabels={dates ? dates : [
                        "06/13/2023",
                        "06/14/2023",
                        "06/15/2023",
                        "06/16/2023",
                        "06/17/2023",
                        "06/18/2023",
                        "06/19/2023",
                        "06/20/2023",
                        "06/21/2023",
                        "06/22/2023",
                    ]}
                    chartData={[
                        ...foundAdsByCategory.map((item, index) => {
                            return {
                                name: categories && isLoaded ? categories[index].name : "Catégorie",
                                type: 'area',
                                fill: 'gradient',
                                data: item,
                            }
                        })
                    ]}
                    generateCSVObject={() => {generateCSVCategoryFounds(foundAdsByCategory)}}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={8} my={2}>
                <AppWebsiteVisits
                    title="Objet restitué par catégorie et par jour"
                    chartLabels={dates ? dates : [
                        "06/13/2023",
                        "06/14/2023",
                        "06/15/2023",
                        "06/16/2023",
                        "06/17/2023",
                        "06/18/2023",
                        "06/19/2023",
                        "06/20/2023",
                        "06/21/2023",
                        "06/22/2023",
                    ]}
                    chartData={[
                        ...sentAdsByCategory.map((item, index) => {
                            return {
                                name: categories && isLoaded ? categories[index].name : "Catégorie",
                                type: 'area',
                                fill: 'gradient',
                                data: item,
                            }
                        })
                    ]}
                    generateCSVObject={() => {generateCSVCategoryFounds(sentAdsByCategory)}}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={8} my={2}>
                <AppConversionRates
                    title="Objets par catégorie"
                    chartData={adsByCategory}
                    generateCSVCategory={generateCSVCategory}  
                />
            </Grid>
        </Grid>
    );
}