import axios from 'axios';

export const updateConversation = async (id, data) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/conversations/${id}`;

    return axios.put(ApiUrl, data).then((response) => response.data);
}

export const getConversation = async (params = "") => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/conversations${params}`;

    return axios.get(ApiUrl).then((response) => response.data);
}