import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/RadioButtonUnchecked';
import CancelIcon from '@mui/icons-material/Cancel';
import "./PaiementTracking.css"
import { getCheckoutPaiement } from 'src/service/checkoutApi';

export default function PaiementTracking({ annonce }) {
    const [paiementValid, setPaiementValid] = useState(false)
    const [paiementCode, setPaiementCode] = useState(null)
    const [paiementCreateAt, setPaiementCreateAt] = useState(null)
    const [paiementStatus, setPaiementStatus] = useState(null)
    const [paiementAmount, setPaiementAmount] = useState(null)
    const [paiementCurrency, setPaiementCurrency] = useState(null)
    const [paiementError, setPaiementError] = useState(null)

    const getPaiementInfos = async () => {
        await getCheckoutPaiement(annonce.id).then((resp) => {
            setPaiementValid(true)
            if (!resp.paymentCode) {
                setPaiementCode("empty")
                setPaiementStatus("Aucune tentative de paiement n'a été effectué pour cette annonce")
                return;
            }
            
            setPaiementCode(resp.paymentCode)
            setPaiementCreateAt(resp.createdAt)
            setPaiementStatus(resp.paymentStatus)
            setPaiementAmount(resp.amount)
            setPaiementCurrency(resp.currency)
            if (resp.paymentCode === "success") {
                setPaiementCode("success")
                return;
            } else {
                setPaiementCode("error")
            }
            setPaiementValid(true)
        }).catch((error) => {
            if (error.response.status === 404) {
                setPaiementError("Il n'existe pas de checkout pour cette annonce")
            } else {
                setPaiementError("Une erreur est survenue")
            }
            setPaiementValid(false)
        })
    }

    useEffect(() => {
        getPaiementInfos();
    }, []);

    return (
        <Grid container spacing={3} justifyContent="center" mt={2}>
            {paiementValid === true && (
                <>
                    <h3 className="title-paiementtracking">Statut du paiement</h3>
                    <Grid item xs={12} paddingLeft={0}>
                        <div className="container-steps-paiementtracking">
                            <div className={`step-paiementtracking ${paiementCode}`}>
                                <div className={`step-check-paiementtracking ${paiementCode}`}>
                                    {paiementCode === "success" && (
                                        <CheckCircleIcon />
                                    )}
                                    {paiementCode === "error" && (
                                        <CancelIcon />
                                    )}
                                    {paiementCode === "empty" && (
                                        <CircleIcon />
                                    )}
                                </div>
                                <p className={`step-title-paiementtracking`}>{paiementStatus}</p>
                            </div>
                        </div>
                    </Grid>
                    { paiementCode !== "empty" && (
                        <Grid item xs={12}>
                            <h5 className="subTitle-adstracking">Checkout créé le </h5>
                            <p className="details-adstracking">{paiementCreateAt}</p>
                            <h5 className="subTitle-adstracking">Montant à payer</h5>
                            <p className="details-adstracking">{paiementAmount / 100} {paiementCurrency}</p>
                        </Grid>
                    )}
                </>
            )}
            {paiementValid === false && (
                <>
                    <h3 className="title-paiementtracking">{paiementError}</h3>
                </>
            )}
        </Grid>
    )
}