import React from "react";

import AttributeSelector from "./Forms/AttributeSelector";
import CategorySelector from "./Forms/CategorySelector";

export default function Step2() {
  return (
    <>
      <CategorySelector />
      <AttributeSelector />
    </>
  );
}
