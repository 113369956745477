import axios from 'axios';

export const getStockages = async (params = "") => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/stockages${params}`;

    return axios.get(ApiUrl, {headers: {
        'Authorization': `Bearer ` + window.localStorage.getItem('authToken'),
        'Content-Type': 'application/json'
      }}).then((response) => response.data);
}

export const getStockagesById = async (id) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/stockages/${id}`;

    return axios.get(ApiUrl, {headers: {
        'Authorization': `Bearer ` + window.localStorage.getItem('authToken'),
        'Content-Type': 'application/json'
      }}).then((response) => response.data);
}

export const updateStockage = async (id, data) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/stockages/${id}`;

    return axios.put(ApiUrl, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json'
            }
        }).then((response) => response.data);
}

export const createStockage = async (params, data) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/stockages?cat=${params}`;
    return axios.post(ApiUrl, data, {
        headers: {
          'Authorization': `Bearer ` + window.localStorage.getItem('authToken'),
          'Content-Type': 'application/json'
        }
      }).then((response) => response.data);
}