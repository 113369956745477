import React from "react";

export const Settings = () => {

    return (
        <div>
            Settings
        </div>
    )

}