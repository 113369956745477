import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";

export default function FilterSelectorAttributes({
  label,
  dataFilters,
  filteredAttributes,
  setFilteredAttributes,
  ui, 
  uiLabel
}) {
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedValueText, setSelectedValueText] = React.useState("");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    
    setFilteredAttributes((filteredAttributes) => [
      ...filteredAttributes.filter((fatt) => fatt.attribute !== label),
      {
        attribute: label,
        attributeValue: event.target.value,
      },
    ]);
  };


  const handleChangetext = (event) => {
    setSelectedValueText(event.target.value);
    setFilteredAttributes((filteredAttributes) => [
      ...filteredAttributes.filter((fatt) => fatt.attribute !== label),
      {
        attribute: label,
        attributeValue: event.target.value,
      },
    ]);

    }
  return (
    <Box sx={{ minWidth: 160,   mr:  "5px" ,
    mb :  { xs : "10px", md : "inherit" }
    }} >
      <FormControl fullWidth>

      {ui !== "textbox" && 
      <>
        <InputLabel  id={"select" + label+ "-label"}>{label}</InputLabel>
        <Select   
          sx={{
            backgroundColor: "#fff",
          
          }}
          labelId={"select" + label+ "-label"}
          id={"select" + label}
          value={selectedValue}
          label={label}
          onChange={handleChange}
        
        >
          <MenuItem key={-1} value={-1}>
            Toutes
          </MenuItem>
          {dataFilters.map((attribute, key) => (
            <MenuItem key={key} value={attribute.name}>
              {attribute.name}
            </MenuItem>
          ))}
        </Select>

        
        </>
} 
{ui === "textbox" && 
        <TextField 
        fullWidth
        id={label}  label={uiLabel} value={selectedValueText} variant="outlined" onChange={handleChangetext}/>
    
    
    }
      </FormControl>
    </Box>
  );
}
