import React, { useContext, useEffect, useState } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import AnnonceContext from 'src/contexts/AnnonceContext';
import { getStockages } from 'src/service/stockageApi';

export default function BoxSelector() {
  const { formAnnonce, setFormAnnonce } = useContext(AnnonceContext);
  const [options, setOptions] = useState([]);
  
  useEffect(() => {
    getStockages("?motherBox=1").then((res) => {
      setOptions(res);
    });
  }, []);
 
  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        getOptionLabel={(option) => option.boxName}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Boite" />}
        onChange={(evt, val) => {
          setFormAnnonce({ ...formAnnonce, stockage: `api/stockages/${val.id}` });
        }}
      />
    </>
  );
}
