import axios from 'axios';

export const  getNotificationUser = async (user) => {
  const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/notifications?customerId=${user}`;

  return  axios.get(ApiUrl).then((response) => response.data);
}

export const getNotification = async () => {
  const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/notifications`;

  return  axios.get(ApiUrl).then((response) => response.data);
}

export const deleteNotification = async (id) => {
  const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/notifications/${id}`;

  return  axios.delete(ApiUrl).then((response) => response.data);
}