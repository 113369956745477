import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Attribute from "./Attribute";
import AnnonceContext from "../../../contexts/AnnonceContext";
import Upload from "../publier/FormComponents/Forms/upload";
import { updateAnnonce, updateAnnonceImage } from "../../../service/annonceApi";
import { notifySuccess } from "src/service/notificationService";

export default function UpdateAds({ selectedAnnonce, setSelectedAnnonce, userToken = "", categories, updateUserAds }) {

    const [imageUpload, setImageUpload] = useState();
    const [currentCategory, setCurrentCategory] = useState(null);
    const [formAttributes, setFormAttributes] = useState([]);
    const [date, setDate] = useState();
    const [formAnnonce, setFormAnnonce] = useState({
      id: selectedAnnonce.id,
      origin: selectedAnnonce.origin,
      status: selectedAnnonce.status,
      ville: selectedAnnonce.ville,
      details: selectedAnnonce.details,
    });

    const [isComplete, setIsComplete] = useState(false);

    const handleSubmit = async () => {
      updateAnnonce(formAnnonce.id, formAnnonce)
      .then((response) => {
        const ads = response
        const annonceId = response.id;
        ads.annonceAttributes = Object.values(ads.annonceAttributes)
        updateUserAds(ads)
        setSelectedAnnonce(ads)
        notifySuccess('Annonce mise à jour avec succès');
        updateAnnonceImage(annonceId, imageUpload)
      });
    }

    const setAnnonceAttributes = () => {
      const annonceAttributes = selectedAnnonce.annonceAttributes;
      const formAttributes = annonceAttributes.map((attribute) => {
        if (!attribute.uiType)
          attribute.uiType = "";
        return attribute;
      }
      );
      setFormAttributes(formAttributes);
    }

    useEffect(() => {
      const category = categories.find((category) => {
        return category.id === selectedAnnonce.category.id;
      })
      setCurrentCategory(category)
      setAnnonceAttributes()
    }, []);

    useEffect(() => {
      setFormAnnonce({
        ...formAnnonce,
        annonceAttributes: formAttributes,
      });
    }, [formAttributes]);

  return (
    <>
      <Grid container pb={5} sx={{ justifyContent: "center", alignContent: "center" }}>
        {currentCategory && (
          <Grid item xs={11} md={10} pt={5} sx={{display: "grid", alignContent: "center"}} className="adsInputContainer">
            <AnnonceContext.Provider
                value={{
                  formAnnonce: formAnnonce,
                  setFormAnnonce: setFormAnnonce,
                  currentCategory: currentCategory,
                  setCurrentCategory: setCurrentCategory,
                  formAttributes: formAttributes,
                  setFormAttributes: setFormAttributes,
                  handleSubmit: handleSubmit,
                  imageUpload: imageUpload,
                  setImageUpload: setImageUpload,
                  date: date,
                  setDate: setDate,
                  isComplete: isComplete,
                  setIsComplete: setIsComplete,
                }}
            >
              <Attribute isSecret={false} selectedAnnonce={selectedAnnonce}/>
              <Attribute isSecret={true} selectedAnnonce={selectedAnnonce}/>
              <Upload />
              <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Détails"
                  multiline
                  maxRows={4}
                  value={formAnnonce ? formAnnonce.details : ""}
                  onChange={(e) =>
                      setFormAnnonce({ ...formAnnonce, details: e.target.value })
                  }
              />
              <Grid display="flex" justifyContent="center" marginTop={2}>
                  <Button color="primary" onClick={handleSubmit}>
                    Mettre à jour votre annonce
                  </Button>
              </Grid>
            </AnnonceContext.Provider>
          </Grid>
        )}
      </Grid>
    </>
  );
}
