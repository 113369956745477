import React, { useState, useEffect, useContext } from 'react';
import { Avatar, Grid, IconButton, Stack, Typography } from '@mui/material';
import AnnonceContext from '../../../../../contexts/AnnonceContext';
import { getCategories } from 'src/service/categoriesApi';

export default function CategorySelector() {
  const { formAnnonce, setFormAnnonce, setCurrentCategory, setFormAttributes } = useContext(AnnonceContext);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories().then((resp) => {
      setCategories(resp);
    })
  }, []);

  const handleCategory = (category) => {
    setFormAnnonce({
      ...formAnnonce,
      category: `api/categories/${category.id}`,
    });

    setCurrentCategory(category);
    setFormAttributes([]);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-start',
            flexWrap: 'wrap',
          }}
        >
          {/* <Stack 
              sx={{ marginRight: 5 }}
              direction="row"
              spacing={4}
              marginBottom={4}
              
            > */}
          {categories.map((category) => {
            return (
              <Stack key={category.id} sx={{ width: '100px' }}>
                <IconButton onClick={() => handleCategory(category)}>
                  <Avatar alt="" src={`/pictos/${category.image}.svg`} sx={{ width: 68, height: 68 }} />
                </IconButton>
                <Typography sx={{ fontSize: 12, textAlign: 'center' }}>{category.name}</Typography>
              </Stack>
            );
          })}
          {/* </Stack> */}
        </Grid>
      </Grid>
    </>
  );
}
