import axios from 'axios';

export const getCustomersAds = async (id, status = "") => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/customers/${id}/annonces${status}`;

    return axios.get(ApiUrl).then((response) => response.data);
}

export const getCustomer = async (customerId) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/customers/` + customerId;

    return axios.get(ApiUrl).then((response) => response.data);
}