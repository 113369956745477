import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import AnnonceContext from '../../../../../contexts/AnnonceContext';

export default function Textbox({ name, isSecret, attributeValue = "" }) {
  const { setFormAttributes } = useContext(AnnonceContext);
  const [value, setValue] = useState(attributeValue || '');

  return (
    <div>
      <Form.Control
        type="text"
        placeholder={name}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setFormAttributes((formAttributes) => [
            ...formAttributes.filter((att) => att.attribute !== name),
            {
              attribute: name,
              attributeValue: e.target.value,
              isSecret: isSecret,
            },
          ])
        }
        }
      />
    </div>
  );
}
