import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import AnnonceContext from "../../../../../contexts/AnnonceContext";

export default function TextareaBox({ name, isSecret = false, attributeValue = "" }) {
  const { setFormAttributes } = useContext(AnnonceContext);
  const [value, setValue] = useState(attributeValue || "");

  return (
    <div>
      <Form.Control
        as="textarea"
        rows={3}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setFormAttributes((formAttributes) => [
            ...formAttributes.filter((att) => att.attribute !== name),
            {
              attribute: name,
              attributeValue: e.target.value,
              isSecret : isSecret,
            },
          ])
        }
        }
      />
    </div>
  );
}
