import { Typography } from "@mui/material";
import React from "react";

import Upload from "./Forms/upload";

export default function Step3() {

  return (
    <>
      <Typography variant="body1">
       Cliquer pour ajouter ou prendre une photo
      </Typography>

      <Upload />
    </>
  );
}
