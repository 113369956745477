import axios from 'axios';

export const getCheckout = async (id) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/checkout/${id}`;

    return axios.get(ApiUrl,
    {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/pdf; charset=utf-8',
        }
    }).then((response) => response.data);
}

export const createCheckout = async (data) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/checkout`;

    return axios.post(ApiUrl, data).then((response) => response.data);
}

export const getCheckoutTracking = async (id) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/checkout/tracking/${id}`;

    return axios.get(ApiUrl).then((response) => response.data);
}

export const getCheckoutPaiement = async (id) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/checkout/paiement/${id}`;

    return axios.get(ApiUrl).then((response) => response.data);
}