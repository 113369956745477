import { Stepper, Step, StepLabel, Typography, Button, Paper, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import AnnonceContext from '../../../../contexts/AnnonceContext';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import { notifyErrorEmpty } from 'src/service/notificationService';

export default function CustomStepper({ children }) {
  const { activeStep, setActiveStep, formAnnonce, currentCategory } = useContext(AnnonceContext);
  const filterCategory = currentCategory?.attribute.filter((c) => c.isSecret === false);
  const secretCategory = currentCategory?.attribute.filter((c) => c.isSecret === true);
  const status = formAnnonce.status.toLowerCase();
  const steps = ['Date et Lieu', `Objet ${status}`, 'Photo et détails', 'Infos secretes', 'Stockage'];
  const stepDescription = [
    `Quand ?`,
    `Quoi ?`,
    'Ajouter une photo',
    "Détails Secrets",
    "Stockage"
  ];
  const stepComponent = [<Step1 />, <Step2 />, <Step3 />, <Step4 />, <Step5/>];

  const missingAttributeName = (requiredAttribute, annonceAttribute) => {
    const missingAttribute = requiredAttribute.map((r) => {
      console.log(annonceAttribute)
      const finds = annonceAttribute.find((a) => {
        if (a.attribute === r.name) {
          return true;
        }
        return false;
      })
      if (finds)
        return null
      return r.name
    })
    return missingAttribute
  }

  const checkAttribute = () => {
    if (currentCategory === undefined || filterCategory === undefined) {
      notifyErrorEmpty(["catégorie"])
      return false
    }

    const requiredAttribute = filterCategory.filter((c) => c.isRequired === true);
    const annonceAttribute = formAnnonce.annonceAttributes;

    let missingAttribute = missingAttributeName(requiredAttribute, annonceAttribute)

    if (requiredAttribute.length > annonceAttribute.length) {
      if (missingAttribute.length > 0) {
        notifyErrorEmpty(missingAttribute)
      }
      return false
    }

    const requiredAnnonce = annonceAttribute.filter((a) => {
      if (a.attributeValue === undefined || a.attributeValue === '' || a.attributeValue === null)
        return false
      const finds = requiredAttribute.find((r) => {
        if (a.attribute === r.name) {
          return true;
        }
        return false;
      })
      if (finds)
        return true
      return false
    })

    if (currentCategory.name === "Smartphone") {
      const brand = requiredAnnonce.find((a) => a.attribute === "Marque Smartphone")
      if (!brand) {
        const otherBrand = annonceAttribute.find((a) => a.attribute === "Autre marque de Smartphone")
        if (otherBrand)
          requiredAnnonce.push(otherBrand)
      }
    }

    missingAttribute = missingAttributeName(requiredAttribute, requiredAnnonce)

    if (requiredAnnonce.length !== requiredAttribute.length) {
      if (missingAttribute.length > 0) {
        notifyErrorEmpty(missingAttribute)
      }
      return false
    }
    
    return true
  }

  const checkSecret = () => {
    if (currentCategory === undefined || filterCategory === undefined) {
      notifyErrorEmpty(["catégorie"])
      return false
    }

    const secretAttribute = secretCategory.filter((c) => c.isRequired === true);
    const annonceAttribute = formAnnonce.annonceAttributes;

    let missingAttribute = missingAttributeName(secretAttribute, annonceAttribute)

    if (secretAttribute.length > annonceAttribute.length) {
      if (missingAttribute.length > 0) {
        notifyErrorEmpty(missingAttribute)
      }
      return false
    }

    const requiredAnnonce = annonceAttribute.filter((a) => {
      if (a.attributeValue === undefined || a.attributeValue === '' || a.attributeValue === null)
        return false
      const finds = secretAttribute.find((r) => {
        if (a.attribute === r.name) {
          return true;
        }
        return false;
      })
      if (finds)
        return true
      return false
    })

    if (currentCategory.name === "Vêtements") {
      const brand = requiredAnnonce.find((a) => a.attribute === "Marque de vêtement")
      if (!brand) {
        const otherBrand = annonceAttribute.find((a) => a.attribute === "Autre marque de vêtements")
        if (otherBrand)
          requiredAnnonce.push(otherBrand)
      }
    }
    
    missingAttribute = missingAttributeName(secretAttribute, requiredAnnonce)

    if (requiredAnnonce.length !== secretAttribute.length) {
      if (missingAttribute.length > 0) {
        notifyErrorEmpty(missingAttribute)
      }
      return false
    }
    return true
  }

  const checkRequired = () => {
    if (formAnnonce.date === undefined || formAnnonce.date === '') {
      return false;
    }
    if (activeStep === 1 && !checkAttribute()) {
      return false
    }
    if (activeStep === 3 && !checkSecret()) {
     return false; 
    }
    return true
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (!checkRequired())
      return
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const theme = useTheme();
  const isMatchSm = useMediaQuery(theme.breakpoints.down("500"));

  return (
    <div>
      <Paper
        sx={{
          minHeight: 600,
          padding: isMatchSm ? 3 : 5,
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((step, i) => (
            <Step key={i}>
              <StepLabel
                sx={{
                  "& .MuiStepLabel-label": {
                      fontSize: isMatchSm ? "0.5rem" : "0.875rem",
                  },
                }}
              >{step}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Typography variant="h4" sx={{ marginTop: 5, marginBottom: 5 }}>
          {stepDescription[activeStep]}
        </Typography>
        {stepComponent[activeStep]}

        {children}
      </Paper>
      <Button onClick={handleBack} disabled={activeStep === 0} variant="contained">
        Précédent
      </Button>
      <Button onClick={handleNext} disabled={activeStep === steps.length - 1} variant="contained">
        Suivant
      </Button>
    </div>
  );
}
