import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/RadioButtonUnchecked';
import "./AdsTracking.css"
import { getCheckoutTracking } from 'src/service/checkoutApi';

export default function AdsTracking({ annonce }) {
    const [trackingMessage, setTrackingMessage] = useState("");
    const [trackingInfos, setTrackingInfos] = useState([]);
    const [stepName, setStepName] = useState([
        "Création",
        "Prise en charge",
        "En cours d'acheminement",
        "Arrivée sur place",
        "Livraison",
        "Livré",
    ]);
    const [trackingValid, setTrackingValid] = useState(false)

    const getTrackingInfos = async () => {
        getCheckoutTracking(annonce.id).then((resp) => {
            setTrackingInfos(resp.step);
            setTrackingValid(true)
        }).catch((error) => {
            setTrackingValid(false)
        })
    }

    useEffect(() => {
        getTrackingInfos();
    }, []);

    useEffect(() => {
        const activeSteps = trackingInfos.filter((step) => step.status === "STEP_STATUS_ACTIVE");
        if (activeSteps.length > 0) {
            const lastActiveStep = activeSteps.reverse()[0];

            setTrackingMessage(lastActiveStep.labelShort);
        }
    }, [trackingInfos]);

    return (
        <Grid container spacing={3} justifyContent="center" mt={2}>
            {trackingValid === true && (
                <>
                    <h3 className="title-adstracking">Statut de la livraison</h3>
                    <Grid item xs={12} paddingLeft={0}>
                        <div className="container-steps-adstracking">
                        {trackingInfos.map((step, index) => (
                            <div className="step-adstracking" key={step.stepId}>
                                <div className={`step-check-adstracking ${step.status === "STEP_STATUS_ACTIVE" ? "active" : ""}`}>
                                    {step.status === "STEP_STATUS_ACTIVE" ? <CheckCircleIcon /> : <CircleIcon />}
                                </div>
                                <p className={`step-title-adstracking ${step.status === "STEP_STATUS_ACTIVE" ? "active" : ""}`}>{stepName[index]}</p>
                            </div>
                        ))}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <h5 className="subTitle-adstracking">Détails de l'étape de livraison</h5>
                        <p className="details-adstracking">{trackingMessage}</p>
                    </Grid>
                </>
            )}
            {trackingValid === false && (
                <>
                    <h3 className="title-adstracking">Il y a un soucis avec le suivis de cette commande</h3>
                </>
            )}
        </Grid>
    )
}