import React, {useEffect, useState} from 'react';
import { Box, Grid, Badge, Typography, Stack, TextField } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate } from 'react-router-dom';

import { AddBoxDialog } from './AddBoxDialog';
import { CategoriesBox } from './CategoriesBox';
import { getStockages } from 'src/service/stockageApi';

export const Stockage = () => {
  const [stockage, setStockage] = useState([]);
  const [filteredStockage, setFilteredStockage] = useState([]);

  const [addBox, setAddBox] = useState();
  const [filterValue, setFilterValue] = useState(null);
  const history = useNavigate();
  const baseUrl = window.location.origin;

  useEffect(() => {
    getStockages("?motherBox=1").then((resp) => {
      resp.sort((a, b) => a.boxName.localeCompare(b.boxName))
      setStockage(resp);
      setFilteredStockage(resp);
      console.log(resp)
    });
  }, [addBox]);

    const handleClick = id => {
      history("/dashboard/stockage/" + id);
    }

    const handleClickTransfer = () => {
      console.log('transfer')
    }

    const filterBox = () => {
      const filtered = stockage.filter((a) => {
        return a.boxName.toLowerCase().includes(filterValue.toLowerCase())
      })
      setFilteredStockage(filtered);
    }

    useEffect(() => {
      if (filterValue) {
        filterBox();
      } else {
        setFilteredStockage(stockage);
      }
    }, [filterValue]);

    return (
    <div>
      <Typography variant="h3" pb={5}>
        Stockage
      </Typography>
      <Stack mb={2}>
        <TextField
          label="Recherche"
          value={filterValue ? filterValue : ''}
          onChange={(event) => setFilterValue(event.target.value)}
        />
      </Stack>
      <AddBoxDialog buttonText="+ Boîte" addBox={addBox} setAddBox={setAddBox} />
      <Grid container spacing={2} pt={5}>
        {filteredStockage.map((data) => {
          const stockedObjects = (data.annonces) ? data.annonces.filter((a) => {
            return  a.status === 'TROUVÉ'
          }) : [];
        
          return (
            <Grid key={data.id} item xs={2} md={2}>
              <Badge badgeContent={stockedObjects.length} color="error" onClick={() => handleClick(data.id)} className="badge-stockage">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    height: 100,

                    backgroundImage: `url(${baseUrl + '/pictos/box.svg'})`,
                    backgroundSize: 'cover',
                    '&:hover': {
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                ></Box>{' '}
              </Badge>
              <Typography color="success" align="left">
                {data.boxName}
              </Typography>
              {/*  <br />
                <button onClick={() => handleClick(data.id)} style={{backgroundColor:'white',border:'none'}}>Imprimer <Box component={PrintIcon} sx={{color: 'black'}} /></button>
                <br />
              <button style={{border: 'none'}} onClick={() => handleClickTransfer}>Transférer >>></button>

              {data.stockages.length > 0 && <CategoriesBox data={data.stockages} />}*/}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Stockage;
