import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';


export const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  position: 'absolute',
});

export const ShButton = styled(Button)`
    background-color: #1b9098;
    color: #fff;
    width: 80%;
    text-transform: uppercase;
    padding: 6px 12px;
    &:hover {
      background-color: #85c2c8;
    }
  `;