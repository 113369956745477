import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";

export default function FilterSelector({
  label,
  dataFilters,
  selectedValue = 0,
  setSelectedValue,
  filteredAttributes,
  setFilteredAttributes,
}) {

  
  
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setFilteredAttributes([]);
  };

 



  return (
    <Box sx={{ minWidth: 160, width : { xs : "94vw", md : "inherit" } 
    , mr:  "5px" ,
    mb :  { xs : "10px", md : "inherit" }
      }}> 
      <FormControl fullWidth>

    
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          sx={{
            backgroundColor: "#fff",
          
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          label={label}
          onChange={handleChange}
        >
          <MenuItem key={-1} value={0}>
            Toutes
          </MenuItem>
          {dataFilters.map((filter) => (
            <MenuItem key={filter.id} value={filter.id}>
              {filter.name}
            </MenuItem>
          ))}
        </Select>
       
      </FormControl>
    </Box>
  );
}
