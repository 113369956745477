import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {useReactToPrint} from "react-to-print";
import {Box, Button} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CustomSpinner from "../../../pages/CustomSpinner";
import { getAnnonces } from 'src/service/annonceApi';
import { getStockagesById, updateStockage } from 'src/service/stockageApi';
import { notifySuccess } from 'src/service/notificationService';

export default function BoxDetail() {
    let contentStock = []
    let { id } = useParams()
    const [stock, setSotck] = useState([])
    const [boxName, setBoxName] = useState();
    const [loadingBoxContent, setLoadingBoxContent] = useState(true);
    const [update, setUpdate] = useState(false)
    const [updateName, setUpdateName] = useState("")
    const [updateLoading, setUpdateLoading] = useState(false)
    const componentRef = useRef();

    useEffect(() => {
        async function mount() {
            let boxName = null

            await getStockagesById(id).then(res => {
                boxName = res.boxName
                setBoxName(res.boxName)
                console.log(res.annonces)
            })

            await getAnnonces(`?status=TROUVÉ`).then(res => {
                res.map(doc => {
                    if (doc.stockage !== null) {
                        if (doc.stockage.boxName === boxName) {
                            contentStock.push(doc)
                            setSotck(contentStock)
                        }
                    }
                })
            })
            
            setLoadingBoxContent(false)
        }

        mount();
    }, [updateLoading])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Impression sherlook'
    });

    const handleChangeUpdateName = (event) => {
        setUpdateName(event.target.value)
    }

    const handleUpdate = () => {
        setUpdate(!update)
        setUpdateName(boxName)
    }

    const updateBoite = async () => {        
        setUpdateLoading(true)
        await updateStockage(id, {boxName: updateName})
        .then(res => {
            setUpdateLoading(false)
            setUpdate(!update)
            notifySuccess("La boite a été mise à jour !")
        })
    }

    return (
        <div>
            <br />
            {loadingBoxContent ? (<CustomSpinner />) :
                (<>
                        <button onClick={handlePrint} style={{backgroundColor:'white',border:'none'}}>Imprimer <Box component={PrintIcon} sx={{color: 'black'}} /></button><br /><br />
                        <button onClick={handleUpdate} style={{backgroundColor:'white',border:'none'}}>Modifier <Box component={DriveFileRenameOutlineIcon} sx={{color: 'black'}} /></button>
                        <div ref={componentRef}>
                            {update && 
                                <>
                                    <input onChange={handleChangeUpdateName} value={updateName} type="text" placeholder='Nom de la boite' style={{marginRight: '8px'}} />
                                    <Button onClick={updateBoite} variant='outlined'>Enregistrer</Button>  
                                </> 
                            }
                            {updateLoading &&
                                <>
                                    <div style={{width:'10%'}}>
                                        <CustomSpinner />
                                    </div>
                                </>
                            }

                            <h1>{boxName}</h1>
                            <Table size="small">
                                <TableHead sx={{backgroundColor: "#1b9faa"}}>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Lieu</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Catégorie</TableCell>
                                        <TableCell>Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stock.map((item) =>
                                        <TableRow>
                                            <TableCell>{item.id}</TableCell>
                                            <TableCell>{item.ville}</TableCell>
                                            <TableCell>{item.createdAt}</TableCell>
                                            <TableCell>{item.category['name']}</TableCell>
                                            <TableCell>{item.annonceAttributes[0]['attributeValue']}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <p style={{textAlign:'center'}}>{stock.length} élément{stock.length > 1 ? 's' : ' '}</p>
                        </div>
            </>
            )}
        </div>
    );
}