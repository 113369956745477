import React, { useContext } from "react";
import AnnonceContext from "../../../contexts/AnnonceContext";
import Colorcheckbox from "../publier/FormComponents/Forms/colorcheckbox/colorcheckbox";
import SelectField from "../publier/FormComponents/Forms/SelectField";
import TextareaBox from "../publier/FormComponents/Forms/TextareaBox";
import Textbox from "../publier/FormComponents/Forms/Textbox";
import Switch from "../publier/FormComponents/Forms/Switch";
import { Stack } from "@mui/material";

export default function Attribute({ isSecret = false, selectAnnonce }) {
    const { currentCategory, formAttributes } = useContext(AnnonceContext);
    const filterCategory = currentCategory.attribute.filter(
        (c) => c.isSecret === isSecret
    );

    const getAttributeValue = (attribute) => {
        const value = formAttributes.filter(
            (att) => att.attribute === attribute.name
        );
        if (value.length > 0) {
            return value[0].attributeValue;
        } else {
            return null;
        }
    }

    const fieldToDisplay = (attribute) => {
        switch (attribute.ui) {
        case "select":
            return (
                <SelectField
                    data={attribute.attributes}
                    parent={attribute}
                    isSecret={attribute.isSecret}
                    attributeValue={getAttributeValue(attribute)}
                    name={attribute.name}
                />
            );
        case "colorcheckbox":
            return (
                <Colorcheckbox
                    data={attribute.attributes}
                    parent={attribute}
                    isSecret={attribute.isSecret}
                />
            );
        case "textareabox":
            return (
                <TextareaBox
                    data={attribute.attributes}
                    name={attribute.name}
                    isSecret={attribute.isSecret}
                    attributeValue={getAttributeValue(attribute)}
                />
            );
        case "checkbox":
            return (
                <Switch
                    data={attribute.attributes}
                    name={attribute.name}
                    isSecret={attribute.isSecret}
                    attributeValue={getAttributeValue(attribute)}
                />
            );
        case "textbox":
            return <Textbox name={attribute.name} isSecret={attribute.isSecret} attributeValue={getAttributeValue(attribute)}/>;
        default:
            return null;
        }
    };

    return (
        <>
            {filterCategory !== undefined && (
                <Stack>
                    {filterCategory.map((attribute, index) => {
                        return (
                            <Stack key={index} spacing={2}>
                                {attribute.uiLabel}
                                {fieldToDisplay(attribute)}{" "}
                            </Stack>
                        );
                    })}
                </Stack>
            )}
        </>
    );
}
