import { Autocomplete, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import AnnonceContext from '../../../../../contexts/AnnonceContext';

export default function SelectField({ name, data, larg = 200, isSecret = false, attributeValue = "" }) {
  const { setFormAttributes } = useContext(AnnonceContext);
  const [sousSelect, setSousSelect] = useState(false);
  const [isChild, setIsChild] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (attributeValue) {
      const initialValue = data.find(
        (option) => option.name === attributeValue
      );
      setSelectedValue(initialValue || null);
    } else {
      setSelectedValue(null);
    }
  }, [attributeValue, data]);

  data.sort(function (a, b) {
    if (a.sort && b.sort) {
      if (a.sort < b.sort)
        return -1;
      else
        return 1;
    } else {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    }
  });

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box"
        options={data}
        getOptionLabel={(option) => (option.label ? option.label : option.name)}
        value={selectedValue}
        onChange={(evt, val) => {
          if (val !== null) {
            if (val.attributes.length > 0) {
              setSousSelect(val);
              setIsChild(true);
            }
            setSelectedValue(val);
            setFormAttributes((selectAttributes) => [
              ...selectAttributes.filter((att) => att.attribute !== name),
              {
                attribute: name,
                attributeValue: val.name,
                isSecret: isSecret,
                uiLabel: data.uiLabel,
              },
            ]);
          } else {
            setIsChild(false);
          }
        }}
        sx={{ width: larg }}
        renderInput={(params) => <TextField {...params} label={name} />}
      />

      {isChild && (
        <Autocomplete
          disablePortal
          id="combo-box-sousselect"
          options={sousSelect.attributes}
          getOptionLabel={(option) => (option.label ? option.label : option.name)}
          onChange={(evt, val) => {
            if (val !== null) {
              setFormAttributes((selectAttributes) => [
                ...selectAttributes.filter((att) => att.attribute !== `${name}option`),
                {
                  attribute: `${name}option`,
                  attributeValue: val.name,
                  isSecret: isSecret,
                  uiLabel: val.uiLabel,
                },
              ]);
            }
          }}
          sx={{ width: larg }}
          renderInput={(params) => <TextField {...params} label="selection" />}
        />
      )}
    </>
  );
}
